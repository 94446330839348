<template>
  <div class="layout-content">
    <!-- <h1>This is an photo page</h1> -->
    <el-row type="flex" :gutter="10" justify="space-between">
      <el-col
        :xs="24"
        :sm="12"
        :md="8"
        :lg="8"
        v-for="(item, index) in ossList"
        :key="index"
        class="col"
      >
        <PhotoCard :title="item.title" :src="item.url" @click.native="showPhotoDialog(index)" />
      </el-col>
    </el-row>
    <!-- <img src="../assets/test.jpg" alt> -->
    <el-dialog
      :title="currentPhoto.title || '图片'"
      :visible.sync="photoDialogVisible"
      width="90%"
      top="10px"
      center
    >
      <img :src="currentPhoto.url" alt style="width: 100%;" />
      <!-- <span slot="footer" class="dialog-footer">
        <el-button @click="showPhotoDialog = false">取 消</el-button>
        <el-button type="primary" @click="showPhotoDialog = false">确 定</el-button>
      </span>-->
    </el-dialog>
  </div>
</template>

<script>
import OSS from "ali-oss";
import { store } from "../store";
import PhotoCard from "../components/PhotoCard";

export default {
  data: () => {
    return {
      url: "",
      ossClient: null,
      dialogPhoto: {},
      // currentPhoto: {},
      photoDialogVisible: false,
      list: [
        // {
        //   title: "helloworld",
        //   url: ""
        // }
      ],
    };
  },
  computed: {
    ossList() {
      const result = [];
      if (this.ossClient) {
        console.log("this.ossClient", this.ossClient.options.region);
      }
      for (let i in this.list) {
        result.push(Object.assign({}, this.list[i]));
        const fileUrl = this.list[i].url;
        if (fileUrl && this.ossClient) {
          let url = this.ossClient.signatureUrl(fileUrl + "!thumbnail");
          result[i].url = url;
        }
      }
      // result.push(result[0]);
      // result.push(result[0]);
      // result.push(result[0]);
      return result;
    },
    currentPhoto() {
      let photo = {};
      if (photo && this.dialogPhoto && this.dialogPhoto.url && this.ossClient) {
        photo = Object.assign({}, this.dialogPhoto);
        console.log("photo", photo);
        let url = this.ossClient.signatureUrl(photo.url + "!source");
        photo.url = url;
      }
      console.log("photo", photo);
      return photo;
    },
  },
  mounted() {
    // 切换主题
    store.setTheme("light");
    this.getPictures();
  },
  components: { PhotoCard },
  methods: {
    getPictures() {
      const host = process.env.VUE_APP_HOST_URL;
      // 查询照片类型，并且附带 oss 鉴权 token
      this.axios
        .get(host + "/oss/get", {
          params: { withToken: true, category: "photo" },
        })
        .then((response) => {
          const data = response.data.data;
          // console.log(data);
          this.credentials = data.ossToken.credentials;
          this.ossClient = new OSS({
            region: "oss-cn-huhehaote",
            bucket: data.bucket,
            accessKeyId: this.credentials.AccessKeyId,
            accessKeySecret: this.credentials.AccessKeySecret,
            stsToken: this.credentials.SecurityToken,
          });
          this.list = data.list;
          // this.$notify({
          //   title: "加载列表成功",
          //   message: ""
          // });
        });
    },
    // 显示图片弹窗
    showPhotoDialog(index = 0) {
      console.log("click");
      this.dialogPhoto = this.list[index];
      this.photoDialogVisible = true;
    },
  },
};
</script>
<style lang="less" scoped>
.col {
  margin-bottom: 10px;
  text-align: center;
}
</style>
