<template>
  <TransitionFadeUp>
    <div class="img-wrap">
      <img :src="src" alt>
      <div class="photo-title">{{title || "图片"}}</div>
    </div>
  </TransitionFadeUp>
</template>

<script>
import TransitionFadeUp from "./transition/TransitionFadeUp";
export default {
  name: "PhotoCard",
  props: { title: String, src: String },
  components: { TransitionFadeUp }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.img-wrap {
  // padding-bottom: 56.25%; // 9:16
  padding-bottom: 62.5%; // 10:16
  // padding-bottom: 177.77%; // 16:9
  // padding-bottom: 80%; // 4:5
  position: relative;
  border-radius: 10px;
  border: 1px solid #eee;
  background-color: rgba(234, 234, 234, 0.6);
  overflow: hidden;
  box-sizing: border-box;
  // 解决 safari overflow hidden + border-radius 不生效的问题
  // -webkit-mask-image: -webkit-radial-gradient(white, black);
  // -webkit-backface-visibility: hidden;
  // -moz-backface-visibility: hidden;
  z-index: 3;
  transition: all 0.2s;
  & img {
    border-radius: 10px;
    display: block;
    position: absolute;
    width: 100%;
    // height: 100%;
    left: 0;
    top: 0;
    // z-index: 1;
  }
  &:hover {
    box-shadow: 0px 2px 10px rgba(51, 51, 51, 0.6);
    .photo-title {
      bottom: 0px;
    }
  }
}

.photo-title {
  position: absolute;
  text-align: center;
  color: #444;
  background-color: rgba(228, 228, 228, 0.85);
  width: 100%;
  bottom: -40px;
  height: 40px;
  line-height: 40px;
  backdrop-filter: blur(3px); // safari 专用
  transition: bottom 0.2s;
  font-size: 0.8rem;
  z-index: 2;
}
</style>
